import algoliasearch from "algoliasearch"

class AlgoliaClient {
  constructor(hitsPerPage, config) {
    this.client = algoliasearch(config.algoliaAppId, config.algoliaApiKey)
    this.searchIndices = {}
    this.currentIndexName = ""
    this.searchOptions = {
      hitsPerPage,
      page: 0,
      highlightPreTag: "<strong>",
      highlightPostTag: "</strong>",
    }
    this.searchIndexNames = [
      {
        displayName: "Fulltext Search",
        algoliaName: "Cs_SearchResult",
        isDefault: true,
      },
    ]

    this.searchIndexNames.forEach(({ algoliaName, displayName, isDefault }) => {
      if (isDefault) {
        this.currentIndexName = displayName
      }
      this.searchIndices[displayName] = this.client.initIndex(algoliaName)
    })
  }

  get searchIndex() {
    return this.searchIndices[this.currentIndexName]
  }

  set searchIndex(displayName) {
    this.currentIndexName = displayName
  }

  search({ query = "", ...customSearchParams }) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    return self.searchIndex.search(query, {
      ...self.searchOptions,
      ...customSearchParams,
    })
  }
}

export default AlgoliaClient
